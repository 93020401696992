var RUPEE_SYMBOL = "₹";
// var BASE_URL = 'https://cranup.in/naswiz/api/'
// var BASE_URL = 'https://cranup.in/new-naswiz/api/'
// var BASE_URL = process.env.NODE_ENV === 'production' ? 'https://myshopwiz.com/master-naswiz/api/' : 'https://myshopwiz.com/master-naswiz/api/'

var BASE_URL = 'https://myshopwiz.com/master-naswiz/api/'
// var BASE_URL = "https://newdemo.myshopwiz.com/master-naswiz/api/";

var MAIN_URL = "https://myshopwiz.com/";

var HEADERS = "application/x.naswiz.v1+json";
var TOKEN = null;

var NaswizHelperApi = {
  GET_WITHOUT_TOKEN: async function(TRAIL_URL, ERROR_MESSAGE) {
    return await fetch(BASE_URL.concat(TRAIL_URL), {
      method: "GET",
      headers: { Accept: HEADERS },
    })
      .then((res) => res.json())
      .then((resData) => resData)
      .catch((e) => {
        ERROR_MESSAGE
          ? console.log(ERROR_MESSAGE, e)
          : console.log("Error in get api", e);
      });
  },
  GET_WITH_TOKEN: async function(TRAIL_URL, ERROR_MESSAGE) {
    var userToken = TOKEN
      ? TOKEN
      : "Bearer ".concat(localStorage.getItem("token"));
    return await fetch(BASE_URL.concat(TRAIL_URL), {
      method: "GET",
      headers: { Accept: HEADERS, Authorization: userToken },
    })
      .then((res) => res.json())
      .then((resData) => resData)
      .catch((e) => {
        ERROR_MESSAGE
          ? console.log(ERROR_MESSAGE, e)
          : console.log("Error in get api", e);
      });
  },
  POST_WITHOUT_TOKEN: async function(TRAIL_URL, BODY, ERROR_MESSAGE) {
    return await fetch(BASE_URL.concat(TRAIL_URL), {
      method: "POST",
      headers: { Accept: HEADERS },
      body: BODY,
    })
      .then((res) => res.json())
      .then((resData) => resData)
      .catch((e) => {
        ERROR_MESSAGE
          ? console.log(ERROR_MESSAGE, e)
          : console.log("Error in post api", e);
      });
  },
  POST_WITH_TOKEN: async function(TRAIL_URL, BODY, ERROR_MESSAGE) {
    var userToken = TOKEN
      ? TOKEN
      : "Bearer ".concat(localStorage.getItem("token"));

    return await fetch(BASE_URL.concat(TRAIL_URL), {
      method: "POST",
      headers: { Accept: HEADERS, Authorization: userToken },
      body: BODY,
    })
      .then((res) => res.json())
      .then((resData) => resData)
      .catch((e) => {
        ERROR_MESSAGE
          ? console.log(ERROR_MESSAGE, e)
          : console.log("Error in post api", e);
      });
  },
};

var colors = {
  themeColor: "#bd3042",
  white: "white",
};

export { NaswizHelperApi, BASE_URL, MAIN_URL, RUPEE_SYMBOL, colors };
