import React, { Component } from 'react';
import { } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarker, faMobile } from '@fortawesome/free-solid-svg-icons'
import { SlideUpDown } from "../../../services/script"
import LogoImage from "../headers/common/logo"
import { NaswizHelperApi } from '../../../NaswizHelper';
import { Container, Button } from "react-floating-action-button";
import { Link } from 'react-router-dom';
import Item from 'antd/lib/list/Item';
import Notification from '../../../Notification'
import renderHTML from 'react-render-html';


class FooterOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginPhoneNumber: '',
            loginModalVisibility: false,
            OtpModalVisibility: false,
            sub_email: '',
            _phone: '',
            uniueId: '',
            _detail: [],
            isHovered: false
        };
        this.handleHover = this.handleHover.bind(this);
    }

    handleHover() {
        this.setState(prevState => ({
            isHovered: !prevState.isHovered
        }));
    }

    async componentDidMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function (elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
        var uniueId2 = await localStorage.getItem('deviceId')
        this.setState({
            uniueId: uniueId2
        })
        await this.naswizGetHomeDataApi();
    }

    naswizGetHomeDataApi = () => {
        var userID = this.props.userID
        var url = 'v1/get-home/' + this.state.uniueId + '/0'

        this.setState({ loading_getHomeData: true }, async () => {
            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')
            if (res && res.success == 1) {
                this.setState({
                    _detail: res.records.detail
                })
                if (this.state._detail.length > 0) {
                    var phone = res.records.detail.find(item => item.name == 'whatsapp');
                    this.setState({
                        _phone: phone.value,
                    })
                }
            }
            else {
                this.setState({
                    loading_getHomeData: false
                })
            }
        })
    }
    handleinputSubmit = (e) => {
        this.naswizGetFooterEmailApi()
    }

    handleKeyPress = (event) => {
        // look for the `Enter` keyCode
        if (event.keyCode === 13 || event.which === 13) {
            this.handleinputSubmit()
        }
    }



    naswizGetFooterEmailApi = async () => {
        var { sub_email } = this.state
        var body = new FormData()
        if (sub_email) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (re.test(sub_email) == false) {
                Notification('error', 'Error!', 'Invaid Email !')
            }
            else {
                body.append('email', sub_email)
                var url = 'v1/subscribe'
                var res = await NaswizHelperApi.POST_WITH_TOKEN(url, body, 'Error => v1/subscribe')

                if (res && res.success == 1) {
                    Notification('success', 'Success!', res.message)
                    this.setState({ sub_email: '' })
                } else {
                    Notification('error', 'Error!', 'Invaid Email !')
                }
            }
        } else {
            Notification('error', 'Error!', 'Please Enter Valid Email Address !')
        }
    }

    onChangeText = e => this.setState({ [e.target.id]: e.target.value })

    render() {
        var { sub_email } = this.state
        var { social_arr, shipping_logo, thecompany_cms, stockpoint_cms, directseller_cms } = this.props
        return (
            <div className="footer-wrapper">

                <div className="container">
                    <div className="footer-middle position-relative">
                        <div className="getintouch-tag bg-theme-primary text-white text-center text-capitalize">
                            Get in touch
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                                <div className="widget">
                                    <h4 className="widget-title text-white text-uppercase">Contact Info</h4>
                                    <ul className="contact-info">
                                        <li>
                                            {social_arr && social_arr.length > 0 ?
                                                social_arr.map((item, index) => {
                                                    return (
                                                        item.name === 'address' && item.value ?
                                                            <>
                                                                <p className="contact-info-label text-white text-uppercase">Address</p>
                                                                <span key={index}>
                                                                    {/* {item.value} */}
                                                                    {renderHTML(String(`${item.value}`))}
                                                                </span>
                                                            </>
                                                            : null
                                                    )
                                                }) : null
                                            }
                                        </li>
                                        <li>
                                            {social_arr && social_arr.length > 0 ?
                                                social_arr.map((item, index) => {
                                                    return (
                                                        item.name === 'phone' && item.value ?
                                                            <>
                                                                <p className="contact-info-label text-white text-uppercase">Phone</p>
                                                                <span key={index}>
                                                                    <a href={`tel:${item.value}`}>{item.value}</a>
                                                                </span>
                                                            </>

                                                            : null
                                                    )
                                                }) : null
                                            }
                                        </li>
                                        <li>
                                            {social_arr && social_arr.length > 0 ?
                                                social_arr.map((item, index) => {
                                                    return (

                                                        item.name === 'whatsapp' && item.value ?
                                                            <>
                                                                <p className="contact-info-label text-white text-uppercase">Whatsapp</p>
                                                                <span key={index}>
                                                                    <a href={`https://wa.me/${item.value}`} target="_blank">{item.value}</a>
                                                                </span>
                                                            </>

                                                            : null
                                                    )
                                                }) : null
                                            }
                                        </li>
                                        <li>
                                            {
                                                social_arr && social_arr.length > 0 ?
                                                    social_arr.map((item, index) => {
                                                        return (

                                                            item.name === 'email' && item.value ?
                                                                <>
                                                                    <p className="contact-info-label text-white text-uppercase">Email</p>
                                                                    <span key={index}>
                                                                        <a href="mailto:Info@naswiz.com">{item.value}</a>
                                                                    </span>
                                                                </>

                                                                : null
                                                        )
                                                    }) : null
                                            }

                                        </li>

                                    </ul>
                                    <div className="social-icons mt-1">
                                        {
                                            social_arr && social_arr.length > 0 ?
                                                social_arr.map((socialitems, index) => {
                                                    if (socialitems.name === 'instagram' || socialitems.name === 'twitter' || socialitems.name === 'facebook' || socialitems.name === 'youtube') {
                                                        return (
                                                            <a key={index} title={socialitems.name} href={`${socialitems.value}`} target="_blank" className="text-center"><i className={socialitems.icon}></i></a>
                                                        )
                                                    }

                                                }) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-8">
                                <div className="widget widget-newsletter">
                                    <h4 className="widget-title text-white text-uppercase">Subscribe newsletter</h4>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <p className="mb-2">Get all the latest information on Events, Sales and Offers. Sign up for newsletter today</p>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            <div className="d-flex mb-0 w-100">
                                                <input type="email" value={this.state.sub_email} className="form-control mb-0" placeholder="Email address" id="subscribe_email" required
                                                    onChange={e => this.setState({ sub_email: e.target.value })}
                                                    onKeyPress={this.handleKeyPress}
                                                />

                                                <input type="button" onClick={() => this.handleinputSubmit()} className="btn theme-button text-white shadow-none" value="Subscribe" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="widget">
                                            {thecompany_cms && thecompany_cms.length > 0 ?
                                                <h4 className="widget-title text-white text-uppercase">The Company</h4>
                                                : null
                                            }
                                            <ul className="links-ul">

                                                {/* <div className="link-part col-lg-12 col-md-12"> */}

                                                {thecompany_cms && thecompany_cms.length > 0 ?
                                                    thecompany_cms.map((company, index) => {
                                                        return (
                                                            company.slug == 'about' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/about-us` }}  >{company.name}</Link></li>
                                                                :
                                                                company.slug == 'Download' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/naswiz-downloads` }}>{company.name}</Link></li>
                                                                    :
                                                                    company.slug == 'Contact' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/Contact` }} onClick={() => localStorage.removeItem("company")} >{company.name}</Link></li>
                                                                        // :
                                                                        // company.slug == 'Exchange-Return-And-Refund' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/Exchange-Return-And-Refund` }}>{company.name}</Link></li>
                                                                        :
                                                                        company.link_type == 0 ?
                                                                            <li key={index}><Link to={{ pathname: `/${company.slug}`, state: company }} onClick={() => localStorage.setItem("company", JSON.stringify(company))}  >{company.name}</Link></li>
                                                                            : <li key={index}><a href={`${company.link}`} target="_blank">{company.name}</a></li>
                                                        )
                                                    }) : null
                                                }


                                                {/* </div> */}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="widget">
                                            {
                                                stockpoint_cms && stockpoint_cms.length > 0 ?
                                                    <h4 className="widget-title text-white text-uppercase">Stock Point</h4>
                                                    : null
                                            }
                                            <ul className="links-ul">
                                                {/* <div className="link-part col-lg-12 col-md-12 child_ul"> */}
                                                {
                                                    stockpoint_cms && stockpoint_cms.length > 0 ?
                                                        stockpoint_cms.map((stock, index) => {
                                                            return (
                                                                stock.slug == 'Our-Stores' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/naswiz_stores` }}>{stock.name}</Link></li> :

                                                                    stock.link_type == 0 ?
                                                                        <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/${stock.slug}`, state: stock }}>{stock.name}</Link></li>
                                                                        :
                                                                        <li key={index}><a href={`${stock.link}`} target="_blank">{stock.name}</a></li>
                                                            )
                                                        }) : null
                                                }
                                                {/* </div> */}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="widget">
                                            {
                                                directseller_cms && directseller_cms.length > 0 ?
                                                    <h4 className="widget-title text-white text-uppercase">Direct Seller</h4>
                                                    : null
                                            }
                                            <ul className="links-ul">

                                                {/* <div className="link-part col-lg-12 col-md-12 child_ul"> */}
                                                {directseller_cms && directseller_cms.length > 0 ?
                                                    directseller_cms.map((direct, index) => {
                                                        return (
                                                            // direct.slug == 'process-of-becoming-a-direct-seller' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/process-of-becoming` }}>{direct.name}</Link></li>
                                                            //     :
                                                            direct.slug == 'grievance-redressal' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/Grievance` }} onClick={() => localStorage.removeItem("company")} >{direct.name}</Link></li>
                                                                :
                                                                direct.link_type == 0 ?
                                                                    // <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/pages/${direct.slug}`, state: direct }}>{direct.name}</Link></li>
                                                                    <li key={index}><Link to={{ pathname: `/${direct.slug}`, state: direct }} onClick={() => localStorage.setItem("company", JSON.stringify(direct))}  >{direct.name}</Link></li>
                                                                    :
                                                                    <li key={index}><a href={`${direct.link}`} target="_blank">{direct.name}</a></li>

                                                        )
                                                    }) : null
                                                }

                                                {/* </div> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="widget">
                                            <h4 className="widget-title text-white text-uppercase">Download App</h4>
                                            <ul className="download-app d-flex">
                                                {/* <li><h4 className="widget-title text-white text-uppercase">Download App</h4></li> */}
                                                <li>
                                                    {
                                                        social_arr && social_arr.length > 0 ?
                                                            social_arr.map((item, index) => {
                                                                //console.log(item);
                                                                return (

                                                                    item.name === 'playstore_app' && item.value ?
                                                                        <a key={index} href={item.value} target="_blank" className="made_by">
                                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/google-play.png`} />
                                                                        </a>
                                                                        : null
                                                                )
                                                            }) : null
                                                    }
                                                </li>
                                                <li>
                                                    {
                                                        social_arr && social_arr.length > 0 ?
                                                            social_arr.map((item, index) => {
                                                                return (

                                                                    item.name === 'appstore_app' && item.value ?
                                                                        <a key={index} href={item.value} target="_blank" className="made_by">
                                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/app-store.png`} />
                                                                        </a>
                                                                        : null
                                                                )
                                                            }) : null
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="widget">
                                            <h4 className="widget-title text-white text-uppercase">Shipping</h4>
                                            <ul className="shipping_logo_ul">
                                                {
                                                    shipping_logo && shipping_logo.length > 0 ?
                                                        shipping_logo.map((item, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <img src={`${item.image_full_path}`} alt="shipping" />
                                                                </li>
                                                            )
                                                        }) : null
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="footer-bottom">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <p className="mb-0 text-center text-md-left" style={{ color: '#979797' }}>
                                    Copyright © {new Date().getFullYear()} Myshopwiz. All rights reserved.
                                </p>
                            </div>
                            <div className="col-md-6 text-right">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/payments.png`} alt="payment methods" className="img-fluid ml-md-auto mr-md-0 mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* mobile menu start */}
                <div className="footerbutton">
                    <Container className="contact-icon">

                        <Button className="icon-mail email-icon"
                            onClick={() => window.open('mailto:info@naswiz.com')}
                            title="Email">
                            <span className="tooltip-txt">Email</span>
                        </Button>

                        <Button className="icon-whatsapp whatsapp-icon"
                            // onClick={() => window.open(`https://api.whatsapp.com/?phone=${this.state._phone}&text&app_absent=0`)}
                            onClick={() => window.open(`https://wa.me/${this.state._phone}`)}
                            title="Whatsapp">
                            <span className="tooltip-txt">whatsapp</span>
                        </Button>

                        <Button className="icon-chat message-icon"
                            rotate={true}>
                        </Button>

                    </Container>
                    <span className={this.state.isHovered ? 'tooltip-txt hide' : 'tooltip-txt show'} >Contact Us</span>
                </div>

                <a id="scroll-top" href="javascript:void(0)" onClick={
                    () => window.scrollTo({
                        top: 0,
                        behavior: 'smooth' // for smoothly scrolling
                    })}
                    title="Top To Scroll" className="fixed text-center">
                    <i className="icon-angle-up"></i>
                </a>

            </div>

        )
    }


}

export default FooterOne;